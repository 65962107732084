let Collection = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

    /**
     * 医院后台统计控制器---疾病收藏统计--中医
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.ZDiseaseList = function (startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/ZDiseaseCollectionTop';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 医院后台统计控制器---疾病收藏统计--西医
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.XDiseaseList = function (startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/XDiseaseCollectionTop';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 医院后台统计控制器---疾病收藏统计--西药
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.XDrugBrowseList = function (startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/XDrugCollectionTop';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 医院后台统计控制器---疾病收藏统计--中成药
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.ZDrugBrowseList = function (startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/ZDrugCollectionTop';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 医院后台统计控制器---中药收藏统计
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.MaterialBrowseList = function (startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/MaterialCollectionTop';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 医院后台统计控制器---中药方剂收藏统计
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.HerbalBrowseList = function (startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/HerbalCollectionTop';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 医院后台统计控制器---食疗药膳收藏统计
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.FoodBrowseList = function (startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/FoodCollectionTop';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    this.CollectionDetail = function (type, entityKey, startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/CollectionDetail';
        if (type) {
            url += `/${type}`;
        }
        if (entityKey) {
            url += `/${entityKey}`;
        }
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}

export { Collection }
